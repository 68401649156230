// // Logo Button Animations
// let logo = document.querySelector('.dreadlogo')
// let logoLink = document.querySelector(`.logoLink`)

// logoLink.addEventListener(`mouseover`, function(){
//     logo.src = 'dist/img/logoClose.png'
// })

// logoLink.addEventListener(`mouseout`, function(){
//     logo.src = 'dist/img/logoOpen.png'
// })


// Burger Button
let burgBtn = document.querySelector(".burg_btn")
let burgMenu = document.querySelector(".home_head nav")
let headBar = document.querySelector(".home_head")
let textBubble = document.querySelector(".text_bubble")

burgBtn.addEventListener("click", function(e){
    e.preventDefault();
    if(burgBtn.id != "open"){
        burgBtn.id = "open"
        burgMenu.id = "active"
        let activeMenu = document.getElementById("active")
        let activeUl = document.querySelector("#active ul")
        // textBubble.style.display = "none"
        setTimeout(() => {
            activeMenu.style.opacity = "100%"
            setTimeout(() => {
                headBar.id = "fixed"
                activeUl.style.transform = "translateX(0%)"
            }, 420);
        }, 420);
    } else if(burgBtn.id != "closed"){
        let activeMenu = document.getElementById("active")
        let activeUl = document.querySelector("#active ul")
        activeUl.style.transform = "translateX(-100%)"
        headBar.id = "unfixed"
        setTimeout(() => {
            activeMenu.style.opacity = "0%"
            // textBubble.style.display = "grid"
            setTimeout(() => {
                burgBtn.id = "closed"
                burgMenu.id = "inactive"
            }, 130);
        }, 420);
    }
})

// wallpaper fade


// let papeVidFade = document.querySelector(".video_foreground")
// let media = document.getElementById("videoBG")

// setInterval(() => {
//     let currentTime = media.currentTime

//     if(currentTime > 34){
//         papeVidFade.style.opacity = "100%"
//     }
//     if(currentTime < 2 ){
//         papeVidFade.style.opacity = "42%"
//     }
// }, 1);



// Animated Color Grid

let blck_container = document.querySelectorAll(".main_bkgd-blur")

let clrArr = ["red", "pink", "purple", "pink", "white", "pink"]


let block = function(){
  let column = Math.floor(Math.random() * 120)
  let row = Math.floor(Math.random() * 360)
  
  let blck = document.createElement("div")
  
  blck.style.backgroundColor = clrArr[Math.floor(Math.random() * clrArr.length)]
//   blck.style.border = "1px solid black"
  blck.style.opacity = "90%"
  blck.style.width = "100%"
  blck.style.hieght = "100%"
  blck.style.gridColumn =  column, "/", column + 1
  blck.style.gridRow = row, "/", row + 1
  blck_container[0].append(blck)
  setTimeout(() => {
    blck.remove()
  }, Math.floor(Math.random() * 4200))
}
let block1 = function(){
    let column = Math.floor(Math.random() * 120)
    let row = Math.floor(Math.random() * 360)
    
    let blck = document.createElement("div")
    
    blck.style.backgroundColor = clrArr[Math.floor(Math.random() * clrArr.length)]
  //   blck.style.border = "1px solid black"
    blck.style.opacity = "90%"
    blck.style.width = "100%"
    blck.style.hieght = "100%"
    blck.style.gridColumn =  column, "/", column + 1
    blck.style.gridRow = row, "/", row + 1
    blck_container[1].append(blck)
    setTimeout(() => {
      blck.remove()
    }, Math.floor(Math.random() * 4200))
  }

setInterval(() => {
    for(let i = 0; i < 13; i ++){
        block()
        block1()
    }
}, 42);



// Auto Card Flip


var cards = document.querySelectorAll('.project_card')
// for (let s = 0; s < cards.length; s++){
//     front = document.querySelectorAll(".project_card .card_front")
//     back = document.querySelectorAll(".project_card .card_back")
//     front[s].style.display = "grid"
//     back[s].style.display = "none"
//     back[s].style.transform = "rotateY(0deg)"
// }
setInterval(() => {
    // cardCheck(0)

    for (let i = 0; i < cards.length; i++) {
        const element = cards[i];
        const elementIsVisibleInViewport = (element, partiallyVisible = false) => {
        const { top, left, bottom, right } = element.getBoundingClientRect();
        const { innerHeight, innerWidth } = window;
        return partiallyVisible
            ? ((top > 0 && top < innerHeight) ||
                (bottom > 0 && bottom < innerHeight)) &&
                ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
            : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
        };
        
        // e.g. 100x100 viewport and a 10x10px element at position
        // {top: -1, left: 0, bottom: 9, right: 10}
        elementIsVisibleInViewport(element); // false - (not fully visible)
        elementIsVisibleInViewport(element, true); // true - (partially visible)
        front = document.querySelectorAll(".project_card .card_front")
        back = document.querySelectorAll(".project_card .card_back")

        if (window.screen.width < 1020){
            if(elementIsVisibleInViewport(element) == true){
                element.classList.add('is-flipped');
                setTimeout(() => {
                    element.style.transform = "rotateY(180deg)"
                }, 1000);
            } else {
                element.classList.remove('is-flipped');
                setTimeout(() => {
                    element.style.transform = ""
                }, 1000);
            }
        } else {
            element.style.transform = ""
            element.addEventListener( 'mouseover', function() {
                element.classList.toggle('is-flipped');
                });
                element.addEventListener( 'mouseout', function() {
                    element.classList.toggle('is-flipped');
                });
        }
    }
}, 42);




// Chainlinks

let skillClass = [['.itemLS', ['c#' ,'php', 'scss', 'javascript', 'css', 'html']], ['.itemMU', ['tailwind.css', 'oop', 'seo']], ['.itemFW', ['next.js', 'react.js', 'node.js']], ['.itemDB', ['aws', 'mongoose', 'mongodb']], ['.itemGE', ['unreal', 'godot', 'unity']]]

let max = 24;
let min = 13;

let anchorSkills = function(skillClass){
    let item = document.querySelector( skillClass[0] + " .ring")
    let chainlinks = Math.floor(Math.random() * (max - min) + min)
    let cpb = Math.floor((chainlinks -1) / skillClass[1].length) //Amount of Chains per Bouy
    let delay = 6000
    
    for (let i = 0; i < chainlinks; i++) {
        let chainClass = "link" + i
        let chainlink = document.createElement("div")
        let bouys = document.querySelectorAll(skillClass[0], ".skillBouy")
        chainlink.classList.add("chainlink")
        chainlink.classList.add(chainClass)
        delay = delay - 1000
        if(i == 0){
            chainlink.classList.add("chainHead")
            item.append(chainlink)
        } else if(i % cpb == 0 && bouys.length < skillClass[1].length){
            console.log("creating bouy")
            let lastClass = skillClass[0] + " .link" + (i - 1)
            let lastLink = document.querySelector(lastClass)
            lastLink.style.animation = 'swaying 42s ease-in-out infinite'
            lastLink.style.animationDelay = delay + "ms"
            let bouy = document.createElement("div")
            let tag = document.createElement('p')
            bouy.classList.add('skillBouy')
            bouy.classList.add('bouy'+ i)
            bouy.append(tag)
            lastLink.append(bouy)
            chainlink.style.left = "50%"
            chainlink.style.top = "50%"
            bouy.append(chainlink)
        } else {
            let lastClass = skillClass[0] + " .link" + (i - 1)
            let lastLink = document.querySelector(lastClass)
            lastLink.append(chainlink)
        }
    }

    let bouys = document.querySelectorAll(skillClass[0] + " .skillBouy p")
    for (let i = 0; i < bouys.length; i ++){
        if(i < skillClass[1].length){
            bouys[i].innerHTML = skillClass[1][i]
        } else {
            let badBouy = document.querySelectorAll(skillClass[0] + " .skillBouy")
            badBouy[i].style.display = "none"
        }
    }
}



for (let i = 0; i < skillClass.length; i++) {
    anchorSkills(skillClass[i])
}

